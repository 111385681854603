<template>
  <div class="popular-tags">
    <div class="text-h6 font-weight-bold mb-4">
      Popular #
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          Most frequently used #hashtags on last 30 posts.
        </span>
      </v-tooltip>
    </div>

    <v-card class="border-radius-1em box-shadow-soft d-flex flex-column justify-between">
      <v-card-text class="flex-grow-1 pb-0">
        <v-list dense>
          <v-list-item
            v-for="item in hashtags.slice(0, 6)"
            :key="item.id"
          >
            <v-list-item-avatar size="30" v-if="item.logo">
              <v-img contain :src="proxyUrl(item.logo)" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="d-flex justify-space-between mb-1">
                #{{ item.tag }}

                <span class="secondary--text">
                  {{ fPercent(item.weight * 100) }}
                </span>
              </v-list-item-title>

              <v-progress-linear
                color="blue"
                :value="item.weight * 100"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          text
          small
          @click="shouldShowDialog = true"
        >
          View More
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="shouldShowDialog"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title>
          Popular Tags
        </v-card-title>

        <v-card-text class="make-it-scrollable">
          <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            hide-default-footer
            disable-pagination
          ></v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "PopularTags",

  // Accept incoming data from parent
  props: {
    userData: {
      type: Object,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    shouldShowDialog: false,

    tableHeaders: [
      {
        text: "Tag",
        value: "tag",
        sortable: false
      },
      {
        text: "Weight",
        value: "weight",
        sortable: false
      },
    ]
  }),

  // Define readonly computable properties
  computed: {
    /**
     * Get the list data from prop
     *
     * @returns {Array}
     */
    hashtags() {
      return this.userData.data.user_profile.top_hashtags
    },

    /**
     * Get the table row items
     *
     * @returns {Array}
     */
    tableItems() {
      return this.hashtags.map((item) => ({
        tag: '#' + item.tag,
        weight: Math.round(item.weight * 10000) / 100 + '%'
      }))
    }
  }
}
</script>
